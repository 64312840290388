<template>
    <!-- User Profile Preference Info Tab Content View From Account Profile -->
    <div class="tabs-content-firstblock right-tab">
        <div>
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive ml-2">
                    <div>
                        <span>Profile</span>
                        <div>Preferences</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-preferen mr-2" style="margin-left: 10px;">
            <div class="" style="background-color: #fff;border-radius: 0px 6px 6px 0px;">
                <div class="d-flex justify-content-between preference-info align-items-center" style="background-color: #00448b;border-radius: 0px 6px 0 0;">
                    <h6 class="subHeaderTitle content-left my-2 text-white pl-6">
                        <i class="crmnew-preference iconsize-16 iconsize" style="font-size: 14px; color: #fff;"></i>
                        <span class="pl-2 fs-14 fw-500 profile-text">Preferences</span>
                      <sup class="fs-14 ml-1 mandatory">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="The options for this field are configurable in the Settings page"
                          placement="right"
                        >
                          <i class="icon-information text-secondary" style="font-size: 14px"></i>
                        </el-tooltip>
                      </sup>
                    </h6>
                    <div v-if="$route.params.id == loggedInUser.user_id" style="margin-right: 10px;">
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                            <button v-if="!edit_enable" @click="showEditEnable" style="padding: 3px 8px !important;background: rgb(255, 255, 255);border-radius: 3px !important;" class="btn btn-icon pointer text-capitalize">
                                <img src="../../assets/Icon material-edit.svg" height="12px;" style="margin-top: -3px;">
                                <span class="ml-1 mr-1" style="color: #00448b;font-size: 14px;font-weight: 600;">Edit</span>
                            </button>
                        </el-tooltip>
                    </div>
                </div>
                <div class="row pb-40" v-if="ajax_call_in_progress">
                    <div class="col-lg-12 py-5 px-3 mt-10">
                        <div class="flex-container">
                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                        </div>
                    </div>
                </div>
                <div v-else class="mr-1 mb-3 content-left" style="padding: 0 15px !important;">
                    <div class="infoHeader pb-4">
                        <div class="mx-3 mt-3">
                            <div class="d-flex align-items-center new-contactView">
                                <div class="fs-14 fw-600 text-secondary profile-content-left-texts">Favourite Food</div>
                                    <div class="w-100">
                                        <div class="w-100" style="position: relative;">
                                            <input class="input-field-underline" maxlength="100"
                                                style="padding-top: 6px !important;"
                                                :readonly="!edit_enable"
                                                v-validate="'alpha_spaces'"
                                                name="favourite_food"
                                                v-model="preferences.food_preferences.favourite_food" :class="{readonly : !edit_enable}">
                                        </div>
                                        <div class="d-flex justify-content-end align-items-center position-absolute" style="right: 0;" v-if="edit_enable">
                                            <span class="fs-13 text-right d-block" style="z-index: 1;">{{(preferences.food_preferences.favourite_food).length}}/100</span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('favourite_food')">
                                            {{errors.first('favourite_food')}}
                                        </span>
                                    </div>
                            </div>
                        </div>
                        <div class="mx-3 mt-6">
                            <div class="d-flex align-items-center new-contactView">
                                <div class="fs-14 fw-600 text-secondary profile-content-left-texts">Favourite Drink</div>
                                    <div class="w-100">
                                        <div class="w-100" style="position: relative;">
                                            <input class="input-field-underline" maxlength="100"
                                                style="padding-top: 6px !important;"
                                                :readonly="!edit_enable"
                                                v-validate="'alpha_spaces'"
                                                name="favourite_drink"
                                                v-model="preferences.food_preferences.favourite_drink" :class="{readonly : !edit_enable}">
                                        </div>
                                        <div class="d-flex justify-content-end align-items-center position-absolute" style="right: 0;" v-if="edit_enable">
                                            <span class="fs-13 text-right d-block" style="z-index: 1;">{{(preferences.food_preferences.favourite_drink).length}}/100</span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('favourite_drink')">
                                            {{errors.first('favourite_drink')}}
                                        </span>
                                    </div>
                            </div>
                        </div>
                        <div class="mx-3 mt-6">
                            <div class="d-flex align-items-center new-contactView">
                                <div class="fs-14 fw-600 text-secondary profile-content-left-texts">Food Allergy</div>
                                    <div class="w-100">
                                        <div class="w-100" style="position: relative;">
                                            <input class="input-field-underline" maxlength="100"
                                                style="padding-top: 2px !important;"
                                                :readonly="!edit_enable"
                                                v-validate="'alpha_spaces'"
                                                name="food_allergy"
                                                v-model="preferences.food_preferences.food_allergy" :class="{readonly : !edit_enable}">
                                        </div>
                                        <div class="d-flex justify-content-end align-items-center position-absolute" style="right: 0;" v-if="edit_enable">
                                            <span class="fs-13 text-right d-block" style="z-index: 1;">{{(preferences.food_preferences.food_allergy).length}}/100</span>
                                        </div>
                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('food_allergy')">
                                            {{errors.first('food_allergy')}}
                                        </span>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="infoHeader">
                        <div class="mt-2">
                            <div class="d-flex align-items-start new-contactView">
                                <h6 class="subHeaderTitle ml-3 profile-content-left-texts">
                                    <span style="color: #00448b;font-weight: 800 !important;"> Dietary Preference</span>
                                </h6>
                                <div class="w-100" v-if="edit_enable == false">
                                    <div v-if="preferences.dietary_preferences.dietary_preferences.length > 0"
                                        class="dietary-container ml-0" @click="dropdownsData('dietary_preferences')">
                                        <span class="dietary-selected"
                                            v-for="(dietary, index) in preferences.dietary_preferences.dietary_preferences"
                                            :key="index">
                                            <span class="chipdietary" style="font-size: 13px !important;">{{ dietary || '-' }}</span>
                                        </span>
                                    </div>
                                    <div v-else class="dietary-container ml-0 w-100" @click="dropdownsData('dietary_preferences')">
                                        <div class="dietary-selected" ></div>
                                    </div>
                                </div>
                                <div v-if="edit_enable == true" style="position: relative;">
                                    <div class="a-q-Jc-j-editcontactView">
                                        <div class="mt-1 mb-3 ml-4">
                                        <label
                                            class="custom-control custom-checkbox digi-checkbox diet-checkbox"
                                            v-for="(dietary, index) in dietary_preferences_data"
                                            :key="index"
                                        >
                                            <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            :value="dietary"
                                            :checked="preferences.dietary_preferences.dietary_preferences && preferences.dietary_preferences.dietary_preferences.indexOf(dietary) != -1"
                                            @change="addDietaryPreference($event, dietary)" :class="{readonly : !edit_enable}"
                                            />
                                            <span class="custom-control-indicator"></span>
                                            <span class="form-check-description fw-400" style="padding-top: 6px;">{{ dietary }}</span>
                                        </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="infoHeader mt-6">
                        <h6 class="subHeaderTitle ml-3">
                            <span style="color: #00448b;">Room Preferences</span>
                        </h6>
                        <div class="row pb-8">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="mx-3">
                                    <div class="d-flex align-items-center new-contactView">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-texts">Room Type</div>
                                        <div class="d-flex align-items-center w-100" :class="{readonly : !edit_enable}">
                                            <multiselect
                                                v-model="preferences.room_preferences.room_type"
                                                @open="dropdownsData('room_type')"
                                                :disabled="!edit_enable"
                                                class="diginew-multiselect diginew-multiselect-underline pointer" id="ajax"
                                                :options="room_type_options"
                                                :show-labels="false"
                                                :loading="room_type_loading"
                                                :multiple="false" :searchable="true" :internal-search="true"
                                                :clear-on-select="false" :close-on-select="true"
                                                :options-limit="280" :max-height="200" :show-no-results="true"
                                                :hide-selected="false" >
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">No Room Types Found</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfListDropdown" />
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="mx-3">
                                    <div class="d-flex align-items-center new-contactView">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-texts">Bed Type</div>
                                        <div class="d-flex align-items-center w-100" :class="{readonly : !edit_enable}">
                                            <multiselect
                                                v-model="preferences.room_preferences.bed_type"
                                                @open="dropdownsData('bed_type')"
                                                :disabled="!edit_enable"
                                                class="diginew-multiselect diginew-multiselect-underline pointer" id="ajax"
                                                :options="bed_type_options"
                                                :show-labels="false"
                                                :loading="bed_type_loading"
                                                :multiple="false" :searchable="true" :internal-search="true"
                                                :clear-on-select="false" :close-on-select="true"
                                                :options-limit="280" :max-height="200" :show-no-results="true"
                                                :hide-selected="false">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">No Bed Types Found</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfListDropdown" />
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                             </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="mx-3 mt-6">
                                    <div class="d-flex align-items-center new-contactView">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-texts">Pillow Type</div>
                                        <div class="d-flex align-items-center w-100" :class="{readonly : !edit_enable}">
                                            <multiselect
                                                v-model="preferences.room_preferences.pillow_type"
                                                @open="dropdownsData('pillow_type')"
                                                :disabled="!edit_enable"
                                                class="diginew-multiselect diginew-multiselect-underline pointer" id="ajax"
                                                :options="pillow_type_options"
                                                :show-labels="false"
                                                :loading="pillow_type_loading"
                                                :multiple="false" :searchable="true" :internal-search="true"
                                                :clear-on-select="false" :close-on-select="true"
                                                :options-limit="280" :max-height="200" :show-no-results="true"
                                                :hide-selected="false">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">No Pillow Types Found</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfListDropdown" />
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="mx-3 mt-6">
                                    <div class="d-flex align-items-center new-contactView"
                                        style="border-bottom: solid 1px transparent !important;">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-texts">Bedsheet Type</div>
                                        <div class="d-flex align-items-center w-100" :class="{readonly : !edit_enable}">
                                            <multiselect
                                                v-model="preferences.room_preferences.bedsheet_type"
                                                @open="dropdownsData('bedsheet_type')"
                                                :disabled="!edit_enable"
                                                class="diginew-multiselect diginew-multiselect-underline pointer" id="ajax"
                                                :options="bedsheet_type_options"
                                                :loading="bedsheet_type_loading"
                                                openDirection="bottom"
                                                :show-labels="false"
                                                :multiple="false" :searchable="true" :internal-search="true"
                                                :clear-on-select="false" :close-on-select="true"
                                                :options-limit="280" :max-height="200" :show-no-results="true"
                                                :hide-selected="false">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">No Bedsheet Types Found</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfListDropdown" />
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="edit_enable" class="d-flex align-items-center justify-content-center pb-8">
                    <button class="btn btn-outline-secondary" :disabled="save_api_call_in_progress" @click="hideEditEnable" style="width: 120px;border-radius: 5px;">Cancel</button>
                    <button class="btn btn-new-success ml-5" :disabled="save_api_call_in_progress || fields_not_changed" @click="updateInfo" style="width: 120px;">Save
                        <hollow-dots-spinner v-if="save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { HollowDotsSpinner } from "epic-spinners";
    import profile from "../mixins/profile";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    export default {
        props: [],
        mixins: [profile],
        components: {
            HalfCircleSpinner,
            HollowDotsSpinner
        },
        data() {
            return {
                ajax_call_in_progress: false,
                event_creation: false,
                edit_user_data: {},
                preference_data: {},
                bed_type_options: [],
                bedsheet_type_options: [],
                pillow_type_options: [],
                room_type_options: [],
                dietary_preferences_data: [],
                add_dietary_preferences: [],
                room_type_loading: false,
                bed_type_loading: false,
                pillow_type_loading: false,
                bedsheet_type_loading: false,
                preferences: {
                    dietary_preferences: {
                        dietary_preferences: [],
                    },
                    food_preferences: {
                        favourite_drink: "",
                        favourite_food: "",
                        food_allergy: "",
                    },
                    room_preferences: {
                        bed_type: "",
                        bedsheet_type: "",
                        pillow_type: "",
                        room_type: ""
                    }
                },
                skip: 0,
                limit: 10,
                which_type: '',
                isVisible: false,
                scrollable: false,
                count: 0,
                edit_enable: false,
                save_api_call_in_progress: false,
                stored_preference_data: null,
                stringified_response: "",
            }
        },
        methods: {
            showEditEnable() {
                this.edit_enable = true
                this.dropdownsData('dietary_preferences')
            },
            hideEditEnable() {
                this.edit_enable = false
                this.preference_data = this.stored_preference_data
                if(this.stored_preference_data.dietary_preferences) {
                    this.add_dietary_preferences = this.stored_preference_data.dietary_preferences.dietary_preferences;
                }
                if(Object.keys(this.preference_data).length == 0) {
                    this.preference_data = {
                        dietary_preferences: {
                            dietary_preferences: [],
                        },
                        food_preferences: {
                            favourite_drink: "",
                            favourite_food: "",
                            food_allergy: "",
                        },
                        room_preferences: {
                            bed_type: "",
                            bedsheet_type: "",
                            pillow_type: "",
                            room_type: ""
                        }
                    }
                } else {
                    if(Object.keys(this.preference_data.food_preferences).length == 0) {
                        this.preference_data.food_preferences = {
                            favourite_drink: "",
                            favourite_food: "",
                            food_allergy: "",
                        }
                    }
                    if(Object.keys(this.preference_data.room_preferences).length == 0) {
                        this.preference_data.room_preferences = {
                            bed_type: "",
                            bedsheet_type: "",
                            pillow_type: "",
                            room_type: ""
                        }
                    }
                }
                this.preferences = this.preference_data;
                this.errors.clear();
            },
            updateInfo() {
                this.addUpdatePreferenceData()
            },
            async preferenceData() {
                this.ajax_call_in_progress = true;
                try {
                    let param = this.$route.params.id ? this.$route.params.id : this.loggedInUser.user_id;
                    let response = await this.getPreferences(param);
                    if(response.status_id == 1) {
                        this.stored_preference_data = JSON.parse(JSON.stringify(response.response))
                        this.preference_data = response.response;
                        if(response.response.dietary_preferences) {
                            this.add_dietary_preferences = response.response.dietary_preferences.dietary_preferences;
                        }
                        if(Object.keys(this.preference_data).length == 0) {
                            this.preference_data = {
                                dietary_preferences: {
                                    dietary_preferences: [],
                                },
                                food_preferences: {
                                    favourite_drink: "",
                                    favourite_food: "",
                                    food_allergy: "",
                                },
                                room_preferences: {
                                    bed_type: "",
                                    bedsheet_type: "",
                                    pillow_type: "",
                                    room_type: ""
                                }
                            }
                        } else {
                            if(Object.keys(this.preference_data.food_preferences).length == 0) {
                                this.preference_data.food_preferences = {
                                    favourite_drink: "",
                                    favourite_food: "",
                                    food_allergy: "",
                                }
                            }
                            if(Object.keys(this.preference_data.room_preferences).length == 0) {
                                this.preference_data.room_preferences = {
                                    bed_type: "",
                                    bedsheet_type: "",
                                    pillow_type: "",
                                    room_type: ""
                                }
                            }
                        }
                        this.preferences = this.preference_data;
                    }
                    this.ajax_call_in_progress = false;
                    this.stringified_response = JSON.stringify(this.create_data)
                }
                catch(err) {
                }
            },
            async addUpdatePreferenceData() {
                try {
                    let param = {
                        dietary_preferences: {},
                        food_preferences: {},
                        room_preferences: {}
                    };
                    param.food_preferences.favourite_food = this.preferences.food_preferences.favourite_food;
                    param.food_preferences.favourite_drink = this.preferences.food_preferences.favourite_drink
                    param.food_preferences.food_allergy = this.preferences.food_preferences.food_allergy
                    param.food_preferences.id = this.preferences.food_preferences.id;
                    param.room_preferences.room_type = this.preferences.room_preferences.room_type == null ? "" : this.preferences.room_preferences.room_type
                    param.room_preferences.bed_type = this.preferences.room_preferences.bed_type == null ? "" : this.preferences.room_preferences.bed_type
                    param.room_preferences.pillow_type = this.preferences.room_preferences.pillow_type == null ? "" : this.preferences.room_preferences.pillow_type
                    param.room_preferences.bedsheet_type = this.preferences.room_preferences.bedsheet_type == null ? "" : this.preferences.room_preferences.bedsheet_type
                    param.room_preferences.id = this.preferences.room_preferences.id;
                    this.add_dietary_preferences.forEach((d, index)=>{
                        this.preferences.dietary_preferences.dietary_preferences.push(d);
                    })
                    param.dietary_preferences.dietary_preferences = [...new Set(this.preferences.dietary_preferences.dietary_preferences)];
                    if(this.preferences.dietary_preferences.id) {
                        param.dietary_preferences.id = this.preferences.dietary_preferences.id;
                    }
                    this.save_api_call_in_progress = true
                    let response = await this.updatePreferences(param, this.loggedInUser.user_id);
                    if(response.status_id == 1) {
                        this.preferenceData();
                        this.save_api_call_in_progress = false
                        this.edit_enable = false
                    }
                }
                catch(err) {
                    this.save_api_call_in_progress = false
                }
            },
            addDietaryPreference(event, value) {
                if (event.target.checked) {
                    this.add_dietary_preferences.push(value);
                } else {
                    this.add_dietary_preferences.splice(this.add_dietary_preferences.indexOf(value),1);
                }
            },
            async dropdownsData(type) {
                this.which_type = type;
                if(type == 'room_type') {
                    this.room_type_loading = true;
                }
                else if(type == 'pillow_type') {
                    this.pillow_type_loading = true;
                }
                else if(type == 'bed_type') {
                    this.bed_type_loading = true;
                }
                else if(type == 'bedsheet_type') {
                    this.bedsheet_type_loading = true;
                }
                else if(type == 'dietary_preferences') {
                }
                try {
                    let params = {
                        type: type,
                        skip: this.skip,
                        limit: this.limit
                    };
                    if(type == 'dietary_preferences') {
                        params.limit = 200
                    }
                    let response = await this.getDropdownsData(params);
                    if(response.status_id == 1) {
                        if(type == 'room_type') {
                            this.room_type_options = response.response;
                            this.room_type_loading = false;
                        }
                        else if(type == 'pillow_type') {
                            this.pillow_type_options = response.response;
                            this.pillow_type_loading = false;
                        }
                        else if(type == 'bed_type') {
                            this.bed_type_options = response.response;
                            this.bed_type_loading = false;
                        }
                        else if(type == 'bedsheet_type') {
                            this.bedsheet_type_options = response.response;
                            this.bedsheet_type_loading = false;
                        }
                        else if(type == 'dietary_preferences') {
                            this.dietary_preferences_data = response.response;
                        }
                        this.count = response.response.length;
                    }
                    this.count = response.response.length;
                }
                catch(err) {
                }
            },
            async reachedEndOfListDropdown(reached) {
                this.isVisible = reached;
                if (reached) {
                    if (this.scrollable == false) {
                        if(this.which_type == 'room_type') {
                            this.room_type_loading = true;
                        }
                        else if(this.which_type == 'pillow_type') {
                            this.pillow_type_loading = true;
                        }
                        else if(this.which_type == 'bed_type') {
                            this.bed_type_loading = true;
                        }
                        else if(this.which_type == 'bedsheet_type') {
                            this.bedsheet_type_loading = true;
                        }
                        let params = {
                            limit: 10,
                            type: this.which_type,
                            skip: this.count
                        };
                        let response = await this.getDropdownsData(params);
                        this.temp_dropdown_options = response.response.length;;
                        if (response.skip == this.count) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.response;
                                if(this.which_type == 'room_type') {
                                    tempFiles.forEach(el => {
                                        this.room_type_options.push(el);
                                    });
                                    this.room_type_loading = false;
                                }
                                else if(this.which_type == 'pillow_type') {
                                    tempFiles.forEach(el => {
                                        this.pillow_type_options.push(el);
                                    });
                                    this.pillow_type_loading = false;
                                }
                                else if(this.which_type == 'bed_type') {
                                    tempFiles.forEach(el => {
                                        this.bed_type_options.push(el);
                                    });
                                    this.bed_type_loading = false;
                                }
                                else if(this.which_type == 'bedsheet_type') {
                                    tempFiles.forEach(el => {
                                        this.bedsheet_type_options.push(el);
                                    });
                                    this.bedsheet_type_loading = false;
                                }
                                else if(this.which_type == 'dietary_preferences') {
                                    tempFiles.forEach(el => {
                                        this.dietary_preferences_data.push(el);
                                    });
                                }
                                this.count += response.response.length;
                            } else {
                                this.scrollable = true
                                this.room_type_loading = false;
                                this.pillow_type_loading = false;
                                this.bed_type_loading = false;
                                this.bedsheet_type_loading = false;
                            }
                        }
                        this.room_type_loading = false;
                        this.pillow_type_loading = false;
                        this.bed_type_loading = false;
                        this.bedsheet_type_loading = false;
                    }
                }
            },
        },
        mounted() {
            this.preferenceData();
        },
        beforeRouteLeave (to, from, next) {
            if (this.fields_not_changed == false) {
                let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
        created() {
            const dict = {
            custom: {
                favourite_food: {
                    alpha_spaces:() => "Please enter valid Favorite food",
                },
                favourite_drink: {
                    alpha_spaces:() => "Please enter valid Favorite drink",
                },
                food_allergy: {
                    alpha_spaces:() => "Please enter valid Food allergy",
                },
            }
        }
        this.$validator.localize("en", dict);
        },
        computed: {
            fields_not_changed(){
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            create_data() {
                return {
                    "favourite_food": this.preferences.food_preferences.favourite_food,
                    "favourite_drink": this.preferences.food_preferences.favourite_drink,
                    "food_allergy": this.preferences.food_preferences.food_allergy,
                    "food_allergy": this.preferences.dietary_preferences.dietary_preferences,
                    "bed_type": this.preferences.room_preferences.bed_type,
                    "bedsheet_type": this.preferences.room_preferences.bedsheet_type,
                    "pillow_type": this.preferences.room_preferences.pillow_type,
                    "room_type": this.preferences.room_preferences.room_type,
                }
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
    };
</script>
<style>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .profile-content-left-texts {
        width: 150px;
        min-width: 150px;
        position: relative;
        font-weight: 600;
    }
    .multiselect {
        box-sizing: content-box;
        display: block;
        position: relative;
        width: 100%;
        min-height: 40px;
        text-align: left;
        color: #303031 !important;
        font-weight: 400 !important;
        font-size: 13px !important;
        font-family: 'Poppins', sans-serif !important;
    }
@media only screen and (min-width: 0px) and (max-width: 350px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .info-preferen{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .preference-info{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .infoHeader{
        width: 100% !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
}
@media only screen and (min-width:351px) and (max-width: 400px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .info-preferen{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .preference-info{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .infoHeader{
        width: 342px !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
@media only screen and (min-width:401px) and (max-width: 500px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .info-preferen{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .preference-info{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .infoHeader{
        width: 100% !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
@media only screen and (min-width:501px) and (max-width: 768px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .info-preferen{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .preference-info{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .infoHeader{
        width: 100% !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
.readonly{
    cursor: no-drop !important;
}
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>